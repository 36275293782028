<template>
  <ul class="side_nav">
    <li
      :class="[$route.path.includes('/admin/users') ? 'active' : '']"
      class="side_nav_item">
      <router-link to="/admin/users/all" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/users')"
          height="20px"
          width="20px"
          src="@/assets/Profile_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Profile.svg"
          alt="img" />
        Users
      </router-link>
    </li>
    <li
      :class="[
        $route.path.includes('/admin/sender_ids/pending') ? 'active' : '',
      ]"
      class="side_nav_item">
      <router-link to="/admin/sender_ids/pending" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/sender_ids/pending')"
          height="20px"
          width="20px"
          src="@/assets/lock_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Lock.svg"
          alt="img" />
        Sender IDs
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('/admin/fraud-detection') ? 'active' : '']"
      class="side_nav_item">
      <router-link to="/admin/fraud-detection" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/fraud-detection')"
          height="20px"
          width="20px"
          src="@/assets/lock_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Lock.svg"
          alt="img" />
        Fraud Detection
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('/admin/reseller-fraud-detection') ? 'active' : '']"
      class="side_nav_item">
      <router-link to="/admin/reseller-fraud-detection" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/reseller-fraud-detection')"
          height="20px"
          width="20px"
          src="@/assets/lock_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Lock.svg"
          alt="img" />
        Reseller Fraud Detection
      </router-link>
    </li>
    <li
      :class="[
        $route.path.includes('/admin/wallet_history_fr') ? 'active' : '',
      ]"
      class="side_nav_item">
      <router-link to="/admin/wallet_history_fr" class="side_nav_link">
        <div class="" style="width: 100%">
          <img
            class="mr-3 ml-2"
            v-if="$route.path.includes('/admin/wallet_history_fr')"
            height="20px"
            width="20px"
            src="@/assets/Wallet__active.svg"
            alt="img" />
          <img
            class="mr-3 ml-2"
            v-else
            height="20px"
            width="20px"
            src="@/assets/Wallet.svg"
            alt="img" />
          Wallet History
        </div>
      </router-link>
    </li>
    <li
      :class="[
        $route.path == '/admin/sender_ids/PendingReseller' ? 'active' : '',
      ]"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/sender_ids/PendingReseller" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/sender_ids/PendingReseller')"
          height="20px"
          width="20px"
          src="@/assets/lock_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Lock.svg"
          alt="img" />
        Reseller Sender IDs
      </router-link>
    </li>
    <li
      :class="[$route.path == '/admin/mass_messages' ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/mass_messages" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path == '/admin/mass_messages'"
          height="20px"
          width="20px"
          src="@/assets/message_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/default.svg"
          alt="img" />
        Mass Messages
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('finance') ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/finance" class="side_nav_link">
        <div class="" style="width: 100%">
          <img
            class="mr-3 ml-2"
            v-if="$route.path.includes('/admin/finance')"
            height="20px"
            width="20px"
            src="@/assets/Wallet__active.svg"
            alt="img" />
          <img
            class="mr-3 ml-2"
            v-else
            height="20px"
            width="20px"
            src="@/assets/Wallet.svg"
            alt="img" />
          Finance
        </div>
      </router-link>
    </li>
    <!-- /birthday-settings -->
    <li
      :class="[$route.path == '/admin/notifications' ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/notifications" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path == '/admin/notifications'"
          height="20px"
          width="20px"
          src="@/assets/Notification.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Notification (1).svg"
          alt="img" />
        Notifications
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('resellers') ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/resellers/pending" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('resellers')"
          height="20px"
          width="20px"
          src="@/assets/Ticket Star (1).svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Ticket Star.svg"
          alt="img" />
        Resellers
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('/admin/reporting') ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/reporting/industries" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/admin/reporting')"
          height="20px"
          width="20px"
          src="@/assets/reporting_active.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/reporting.svg"
          alt="img" />
        Reporting
      </router-link>
    </li>
    <li
      :class="[$route.path.includes('/admin/promo_codes') ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/promo_codes" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('/promo_codes')"
          height="20px"
          width="20px"
          src="@/assets/Ticket Star (1).svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Ticket Star.svg"
          alt="img" />
        Promos & Referrals
      </router-link>
    </li>
    <li
      :class="[$route.path == '/admin/industries' ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/industries" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path == '/admin/industries'"
          height="20px"
          width="20px"
          src="@/assets/Ticket Star (1).svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Ticket Star.svg"
          alt="img" />
        Industries
      </router-link>
    </li>
    <li
      :class="[$route.path == '/admin/international_rates' ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/international_rates" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path == '/admin/international_rates'"
          height="20px"
          width="20px"
          src="@/assets/Ticket Star (1).svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Ticket Star.svg"
          alt="img" />
        International Rates
      </router-link>
    </li>
    <!-- Settings -->
    <li
      :class="[$route.path.includes('/admin/settings') ? 'active' : '']"
      class="side_nav_item"
      v-if="!disallowedUsers">
      <router-link to="/admin/settings" class="side_nav_link">
        <img
          class="mr-3 ml-2"
          v-if="$route.path.includes('admin/settings')"
          height="20px"
          width="20px"
          src="@/assets/SettingActive.svg"
          alt="img" />
        <img
          class="mr-3 ml-2"
          v-else
          height="20px"
          width="20px"
          src="@/assets/Setting.svg"
          alt="img" />
        Settings
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "mobile_menu",
    data() {
      return {};
    },
    computed: {
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
  };
</script>

<style scoped lang="scss">
  .side_nav {
    list-style: none;
    margin: 0px;
    padding: 0px;

    .side_nav_item {
      padding-top: 0.8em;
      padding-left: 0.5em;
      height: 50px;
    }

    .side_nav_link {
      text-decoration: none;
      width: inherit;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      text-transform: capitalize;
      color: #484a4f;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
</style>
